import React from "react"
import { gsap, ScrollTrigger } from "wildGsap"

import { Box } from "@chakra-ui/react"
import { Link } from "gatsby"
import StarIcon from "../../assets/icons/StarIcon"
import VerticalCard from "../common/VerticalCard"
import { useEffect } from "react"

function PostItem({ post }) {
  const containerRef = React.useRef()
  const imageRef = React.useRef()
  const observer = React.useRef()

  useEffect(() => {
    observer.current = ScrollTrigger.observe({
      target: containerRef.current,
      type: "pointer",
      debounce: false,
      onHover: () => {
        gsap.to(imageRef.current, {
          rotate: "60deg",
          duration: 0.3,
          ease: "power3.out",
        })
      },
      onHoverEnd: () => {
        gsap.to(imageRef.current, {
          rotate: "-60deg",
          duration: 0.3,
          ease: "power3.out",
        })
      },
    })
    return () => {
      observer.current.kill()
    }
  }, [])

  return (
    <Box
      position="relative"
      className="post-item"
      key={"post-" + post.id}
      ref={containerRef}
    >
      <Link to={post.uri}>
        <VerticalCard
          image={
            post.featuredImage?.node.localFile.childImageSharp.gatsbyImageData
          }
          title={post.title}
          published={post.date}
          readTime={post.seo.readingTime}
          excerpt={post.excerpt}
          useGatsbyImage={true}
          as={"li"}
        />
        <StarIcon
          color="#62c6c1"
          position="absolute"
          right="-100px"
          top="10%"
          width="200px"
          height="200px"
          ref={imageRef}
        />
      </Link>
    </Box>
  )
}

export default PostItem
