import React from "react"
import { Link } from "gatsby"
import { Button, Center, Icon, IconButton, Wrap } from "@chakra-ui/react"
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md"
import { AiOutlineEllipsis } from "react-icons/ai"

export const Pagination = ({ ctx, anchor }) => {
  const {
    humanPageNumber: currentPage,
    numberOfPages,
    previousPagePath,
    nextPagePath,
    seo,
  } = ctx
  const elements = []
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfPages

  const paginationButton = (i, dots) => {
    return (
      <>
        {dots && dots.showPreviousDots && (
          <IconButton
            variant="outlineWhite"
            color="dark.default"
            alignItems="flex-end"
            p="0"
            cursor="default"
            aria-label="placeholder"
            icon={
              <Icon
                name="ellipsis"
                as={AiOutlineEllipsis}
                boxSize="26px"
                transform="translateY(7px)"
              />
            }
          />
        )}
        <Link
          key={i}
          color="dark.default"
          to={
            i === 0
              ? `${seo.page.canonical}${anchor ? anchor : ""}`
              : `${seo.page.canonical}/page/${i + 1}${anchor ? anchor : ""}`
          }
        >
          <Button
            variant={
              i + 1 === currentPage ? "paginationCurrent" : "paginationNav"
            }
            color="dark.default"
            isDisabled={i + 1 === currentPage ? true : false}
          >
            {i + 1}
          </Button>
        </Link>
        {dots && dots.showAfterDots && (
          <IconButton
            variant="outlineWhite"
            color="dark.default"
            alignItems="flex-end"
            p="0"
            cursor="default"
            aria-label="placeholder"
            icon={
              <Icon
                name="ellipsis"
                as={AiOutlineEllipsis}
                boxSize="26px"
                transform="translateY(7px)"
              />
            }
          />
        )}
      </>
    )
  }

  if (!isFirst) {
    elements.push(
      <Link key="prev" to={previousPagePath}>
        <IconButton
          variant="paginationNav"
          icon={<Icon as={MdNavigateBefore} boxSize="26px" />}
        />
      </Link>
    )
  }

  Array.from({ length: numberOfPages }).forEach((page, i) => {
    const pageNumber = i + 1

    // first page
    if (pageNumber === 1) {
      elements.push(paginationButton(i))
    }

    // last page
    else if (pageNumber === numberOfPages) {
      elements.push(paginationButton(i))
    }

    // current page
    else if (pageNumber === currentPage) {
      elements.push(paginationButton(i))
    }

    // page before
    else if (pageNumber === currentPage - 1) {
      const showPreviousDots = currentPage - 1 > 2 && numberOfPages > 3
      elements.push(paginationButton(i, { showPreviousDots }))
    }

    // page after
    else if (pageNumber === currentPage + 1) {
      const showAfterDots =
        currentPage + 1 < numberOfPages - 1 && numberOfPages > 3
      elements.push(paginationButton(i, { showAfterDots }))
    }
  })

  if (!isLast) {
    elements.push(
      <Link key="next" to={nextPagePath}>
        <IconButton
          variant="paginationNav"
          icon={<Icon as={MdNavigateNext} boxSize="26px" />}
        />
      </Link>
    )
  }

  return (
    <Center w="full" pt={12}>
      <Wrap spacing={2} align="center">
        {elements}
      </Wrap>
    </Center>
  )
}
