import React from "react"
import Layout from "wildComponents/Layout"
import { graphql } from "gatsby"
import { Seo } from "wildComponents/seo/components"
import PostGrid from "../sections/shared/PostGrid"
import Hero from "../sections/page-specific/blog/Hero"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function Blog(props) {
  const { allWpPost } = props.data
  const categories = props.data.allWpCategory.edges
  const isFirstPage = props.pageContext.humanPageNumber === 1

  return (
    <Layout darkTopBar={!isFirstPage}>
      {isFirstPage && <Hero />}
      <PostGrid
        posts={allWpPost.nodes}
        ctx={props.pageContext}
        categories={categories}
      />
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    wpPage(isPostsPage: { eq: true }) {
      title
    }
    allWpPost(limit: $limit, skip: $skip, sort: { date: DESC }) {
      nodes {
        id
        uri
        slug
        title
        excerpt
        date(formatString: "DD MMMM YYYY")
        seo {
          readingTime
        }
        featuredImage {
          node {
            altText
            databaseId
            localFile {
              childImageSharp {
                gatsbyImageData
                original {
                  height
                  src
                  width
                }
              }
            }
          }
        }
        categories {
          nodes {
            id
            slug
            name
            uri
          }
        }
        template {
          templateName
        }
        author {
          node {
            name
            slug
            uri
            avatar {
              url
            }
          }
        }
        tags {
          nodes {
            name
            slug
            uri
          }
        }
      }
    }
    allWpCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          databaseId
          uri
          slug
        }
      }
    }
  }
`
