import React from "react"
import { graphql } from "gatsby"

import Section from "wildComponents/Section"
import SearchToggle from "../../components/search/SearchToggle"
import PostItem from "../../components/post/PostItem"
import { Pagination } from "../../components/archive/Pagination"
import { Container, Select, SimpleGrid, Stack } from "@chakra-ui/react"

function PostGrid({ posts, ctx, categories }) {
  const [selectedPosts, setSelectedPosts] = React.useState(posts)

  function filterCategories(e) {
    const selection = Array.from(e.target).filter((option, i) => {
      return option.selected
    })
    const category = selection[0].value
    const filteredPosts = posts.filter(post => {
      return post.categories.nodes.some(cat => {
        return cat.slug === category
      })
    })
    filteredPosts.length
      ? setSelectedPosts(filteredPosts)
      : setSelectedPosts(posts)
  }

  return (
    <Section minH="auto" id="post-grid" className="light">
      <Container h="100%" maxW={["container.lg", "container.lg", "100%"]}>
        <Stack
          spacing={3}
          mb={20}
          align="start"
          direction={["column", "column", "column", "row"]}
        >
          <SearchToggle />
          <Select
            flex="1"
            bg="#F6F6F6"
            border="1px solid #DEDEDE"
            size="lg"
            placeholder="All categories"
            color="dark.700"
            onChange={e => {
              filterCategories(e)
            }}
          >
            {categories.map(cat => {
              return (
                <option key={cat.node.slug} value={cat.node.slug}>
                  {cat.node.name}
                </option>
              )
            })}
          </Select>
        </Stack>
        <SimpleGrid
          as="ul"
          columns={{ base: 1, lg: 2 }}
          spacingY={24}
          spacingX={40}
        >
          {selectedPosts.map(post => {
            return <PostItem post={post} />
          })}
        </SimpleGrid>

        <Pagination ctx={ctx} />
      </Container>
    </Section>
  )
}

export default PostGrid

export const query = graphql`
  fragment POSTS_QUERY on WpPostConnection {
    nodes {
      id
      uri
      slug
      title
      excerpt
      date(formatString: "DD MMMM YYYY")
      seo {
        readingTime
      }
      featuredImage {
        node {
          altText
          databaseId
          localFile {
            childImageSharp {
              gatsbyImageData
              original {
                height
                src
                width
              }
            }
          }
        }
      }
      categories {
        nodes {
          id
          slug
          name
          uri
        }
      }
      template {
        templateName
      }
      author {
        node {
          name
          slug
          uri
          avatar {
            url
          }
        }
      }
      tags {
        nodes {
          name
          slug
          uri
        }
      }
    }
  }
`
